import React from 'react'
import AboutImg from '../assets/about-img.png';
// import AboutImg1 from '../assets/about-img1.png';

const AboutInfo = () => {
  return (
    <>
      <section className='my-5'>
        <div className='container'>

          <div className='row'>

            <div className='col-lg-6 col-12 order-last order-lg-first'>
              <img src={AboutImg} className='img-fluid' alt='About Section' />
            </div>

            <div className='col-lg-6 col-12 my-2'>
              <h2 className='fw-bold mb-4'>Empowering Towards Secure Future</h2>
              <hr className='hr-line' />
              <p className='mt-5'>ResQShield provides a innovative emergency alert solutions, dedicated to ensuring the safety and well-being of individuals in critical situations.</p>
              <p>With ResQShield team by your side, you can have peace of mind knowing that help is just a click away. Join our community today and experience the reliability, speed, and efficiency of ResQ in safeguarding your well-being.</p>
              <p>With features like location sharing, instant emergency alerts, SOS buttons, and audio/video recording, it empowers women to stay
                safe and connected. Our safety application ensures quick access to help, offering peace of mind in critical situations.</p>

              <p className='fst-italic fw-bold mt-4'>Our ResQShield Application Features: -<br /><br />
                <i class="fa-solid text-danger fa-location-dot"></i> Live Location Sharing<br /><br />
                <i class="fa-solid text-danger fa-lightbulb"></i> Emergency Active<br /><br />
                <i class="fa-solid text-danger fa-shield-halved"></i> Night Safety Mode
              </p>

            </div>


          </div>
          {/* row ends */}

        </div>
      </section>
    </>
  )
}

export default AboutInfo
