import React from 'react'

const Faq = () => {
    return (
        <>
            <section className='bg-light py-5'>
                <div className='container text-center'>

                    <h2 className='fw-bold mb-4'>Frequently Asked Questions</h2>
                    <hr className='hr-line mx-auto' />


                    <div class="accordion accordion-flush mt-5" id="accordionExample">
                        <div class="accordion-item my-3">
                            <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    What is ResQShield?
                                </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    ResQShield is an innovative emergency response app designed to provide quick assistance during emergencies. It connects users with emergency services, including police, ambulance, and other support systems, to ensure help is just a click away.
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item my-3">
                            <h2 class="accordion-header" id="headingTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    How does ResQShield work?
                                </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    ResQShield operates by sending an emergency alert to the nearest responders, sharing your real-time location, and providing a platform for seamless communication. It ensures that help reaches you as quickly as possible.
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item my-3">
                            <h2 class="accordion-header" id="headingThree">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    What does ResQShield offer?
                                </button>
                            </h2>
                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Some key features of ResQShield include: -<br />
                                    •	One-click emergency alerts.<br />
                                    •	Real-time location sharing for faster assistance.<br />
                                    •	Integrated communication with emergency responders.<br />
                                    •	An option to connect with nearby volunteers for immediate aid.<br />
                                    •	An in-app “Ambulance” button for direct medical emergency assistance.
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item my-3">
                            <h2 class="accordion-header" id="headingFour">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    Who can use ResQShield?
                                </button>
                            </h2>
                            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    ResQShield is for anyone who wants to feel safer and prepared during emergencies. Whether you’re at home, traveling, or in public spaces, ResQShield is designed to assist you when you need it most.
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item my-3">
                            <h2 class="accordion-header" id="headingFive">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    Is my data safe with ResQShield?
                                </button>
                            </h2>
                            <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    Absolutely. We prioritize your privacy and data security. All your information is encrypted, and we adhere to strict data protection policies to ensure your safety.                                </div>
                            </div>
                        </div>

                        <div class="accordion-item my-3">
                            <h2 class="accordion-header" id="headingSix">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                    How is ResQShield different from calling emergency numbers directly?
                                </button>
                            </h2>
                            <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    ResQShield enhances emergency response by: -<br />
                                    •	Automatically sharing your location, reducing response time.<br />
                                    •	Allowing you to send alerts with just one tap, even if you’re unable to speak.<br />
                                    •	Enabling direct communication with responders within the app.
                                </div>
                            </div>
                        </div>


                        <div class="accordion-item my-3">
                            <h2 class="accordion-header" id="headingSeven">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                    Can orgnisations or institutions use ResQShield?
                                </button>
                            </h2>
                            <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                Yes, ResQShield offers integration options for schools, businesses, and other organizations to enhance safety measures for their employees, students, or members. Contact us at ResQshieldinfo@gmail.com for more details.
                                </div>
                            </div>
                        </div>


                        <div class="accordion-item my-3">
                            <h2 class="accordion-header" id="headingEight">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                How can I provide feedback or suggestions for the app?
                                </button>
                            </h2>
                            <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                We value your feedback! You can share your thoughts or suggestions through the Contact section in the
                                website or email us at support@resQShield.com .</div>
                            </div>
                        </div>



                    </div>
                    {/* accordion ends */}


                </div>
            </section>
        </>
    )
}

export default Faq
