import React from 'react'
import AboutInfo from '../general/AboutInfo';
import SubHeader from '../general/SubHeader';

const About = () => {
  return (
    <>
      <SubHeader message='About Us' />
      <AboutInfo />

      <section className='about-bg py-5'>
        <div className='container'>

          <p className='text-danger fs-5 fw-bold my-0'>Our Mission</p>
          <p className='banner-heading'>Available to ResQ You</p>

          <div className='row'>
            <div className='col-lg-6 col-md-6 col-12 my-2'>
              <div className='mission-card'>
                <p className='fs-4 fw-bold'>Our Vision</p>
                <hr className='hr-line' />
                <p className='text-muted'>Empowering a safe and secure India.<br />
                Our vision is to build a future where safety know no boundaries and where everyone can live their lives to the fullest, knowing that they
                are protected and supported every step of the way.<br />
                Our advanced technology enables us to quickly identify your location, connect you with the nearest responders, and
                ensure that help reaches you in the shortest possible time. With ResQShield by your side, you can have peace of mind knowing
                that help is just a click away.</p>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-12 my-2'>
              <div className='mission-card'>
              <i class="fa-solid fa-bullseye fs-1 text-danger"></i>
              <p className='fs-4 fw-bold mt-4'>Our Mission</p>
                <hr className='hr-line' />
                <p className='text-muted'>We are dedicated to be the leading platform that connects individuals in need with the nearest emergency service providers. We strive to save lives, minimize response time, and create a safer community for everyone.</p>
              </div>
            </div>

          </div>
          {/* inner row ends */}


        </div>
      </section>
    </>
  )
}

export default About
