import React from 'react'
import SubHeader from '../general/SubHeader';


const PrivacyPolicy = () => {
    return (
        <>
            <SubHeader message='Privacy Policy' />

            {/* Privacy Policy section */}
            <section className='my-5'>
                <div className='container'>

                    <p className='fs-4 fw-bold'>Privacy Policy for ResQ Infratech - ResQShield</p>
                    <p className='para-text text-muted'>At ResQ Infratech, we value the privacy and security of our users. This Privacy Policy outlines how we collect, use, and protect your information when you use our rescue application, ResQShield, which is designed to ensure the safety of individuals by allowing users to share their location during emergencies and add members for emergency alerts.<br />
                        By using ResQShield, you agree to the collection and use of your information in accordance with this policy.</p>
                    <p className='para-text text-muted'>The information we collect is used for the following purposes: -<br />
                        ◉ Provide Emergency Services: To enable the real-time sharing of your location with selected members during an emergency and send notifications for your safety.<br />
                        ◉ Enhance User Experience: To improve ResQShield's functionality, performance, and security.<br />
                        ◉ Safety and Security: To monitor activity and prevent fraudulent behavior, unauthorized access, or misuse of the app.</p>


                    <p className='fs-5 fw-bold'>Summary: Key Points</p>
                    <p className='para-text text-muted'>This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for.<br /><br />
                        ◉ What personal information do we process? When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about personal information you disclose to us.<br />
                        ◉ Do we process any sensitive personal information? We do not process sensitive personal information.<br />
                        ◉ Do we collect any information from third parties? We do not collect any information from third parties.<br />
                        ◉ How do we process your information? We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about how we process your information.<br />
                        ◉ In what situations and with which parties do we share personal information? We may share information in specific situations and with specific third parties. Learn more about when and with whom we share your personal information.<br />
                        ◉ How do we keep your information safe? We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about how we keep your information safe.<br />
                        ◉ What are your rights? Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about your privacy rights.</p>

                    <p className='fs-5 fw-bold'>Sharing your Information</p>
                    <p className='para-text text-muted'>We do not sell, rent, or share your personal information with third parties except in the following circumstances: -<br />
                        ◉ With Emergency Contacts: When you share your location during an emergency, the location data is shared with the members you have added to your account.<br />
                        ◉ Legal Requirements: We may disclose your information if required by law or in response to a valid legal process (e.g., subpoena, court order).<br />
                        ◉ Service Providers: We may share your information with trusted third-party service providers who help us operate the app or provide services to you, but they will only have access to the information necessary for them to perform their tasks.</p>

                    <p className='fs-5 fw-bold'>Changes to this Privacy Policy</p>
                    <p className='para-text text-muted'>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on this page with an updated effective date. We recommend that you review this Privacy Policy periodically to stay informed about how we are protecting your information.</p>

                    <p className='fs-5 fw-bold'>Contact Us</p>
                    <p className='para-text text-muted'>If you have any questions or concerns about this Privacy Policy or the handling of your personal information, please contact us at: -<br />
                        ResQ Infratech<br />
                        Email: resqinfra@gmail.com<br />
                        Phone: 7275786295<br />
                        By using ResQShield, you acknowledge that you have read and understood this Privacy Policy.</p>
                </div>
            </section>
            {/* Privacy Policy section ends */}

        </>
    )
}

export default PrivacyPolicy
