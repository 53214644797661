import React from 'react'
import SubHeader from '../general/SubHeader';

const Tutorials = () => {
    return (
        <>
            <SubHeader message="Tutorials" />

            <section className='my-5'>
                <div className='container'>

                    <div className='row'>

                        <div className='col-lg-4 col-12 my-2'>
                            <iframe title='Your browser does not support the video tag.' 
                            className='video-style' src="https://www.youtube.com/embed/uFheIAsQLUo?autoplay=1&mute=1">
                            </iframe>
                        </div>

                        <div className='col-lg-8 col-12 my-2 align-content-center'>
                            <h2 className='fw-bold mb-4'>How to use ResQShield?</h2>
                            <hr className='hr-line' />
                            <p>Here, you'll find a comprehensive video to help you navigate and make the most out of the ResQShield app.
                                Our step-by-step video tutorial covers everything from setting up your account to using all the key features
                                for maximum safety and convenience.<br /><br />
                                Whether you're new to ResQShield or just need a refresher, this video will walk you through the process. Watch now to get started and enhance your experience with ResQShield!
                                Learn how to stay protected, easily access services, and maximize the app's full potential with ease.</p>
                        </div>

                    </div>
                    {/* row ends */}

                </div>
            </section>

        </>
    )
}

export default Tutorials
