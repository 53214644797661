import React from 'react'
import SubHeader from '../general/SubHeader';
import ContactImg from '../assets/contact-img.webp';
import emailjs from '@emailjs/browser';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';

const Contact = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('resQ_shield', 'template_1contact', form.current, {
        publicKey: 'rhSu233GTktZDZGml',
      })
      .then(
        () => {
          console.log('Form Submitted!');
          alert('Form Submission Successful');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <>
      <SubHeader message='Contact Us' />

      <section className='my-5'>
        <div className='container text-center'>

          <div className='row'>

            <div className='col-lg-6 col-md-6 col-12 my-5'>
              <h2 className='fw-bold mb-4'>Get In Touch with us</h2>
              <hr className='hr-line mx-auto' />


              <form className="row g-3 mt-4 text-start" ref={form} onSubmit={sendEmail}>
                <div className="col-12">
                  <input type="text" className="contact-input" id="inputText4" placeholder='Your Name' name='user_name' required />
                </div>

                <div className="col-md-6">
                  <input type="email" className="contact-input" id="inputEmail4" placeholder='Your Email' name='user_email' required />
                </div>

                <div className="col-md-6">
                  <input type="tel" className="contact-input" id="inputNumber4" placeholder='Mobile Number' name='user_mobile' maxLength={10} required />
                </div>

                <div className="col-12">
                  <textarea type="text" className="contact-input" id="inputAddress" placeholder="Write your Message.." name='message' style={{ height: '9em' }} required />
                </div>

                <div className="col-12">
                  <button type="submit" value='Send' className="banner-btn bg-danger text-white w-100">Submit Now</button>
                </div>
              </form>
              {/* form ends */}
            </div>

            <ScrollAnimation animateIn='flipInY' className='col-lg-4 col-md-6 col-12 my-2 bg-light ms-auto text-start px-4 py-4 order-first order-lg-last order-md-last'>
              <img src={ContactImg} className='img-fluid' alt='Contact' />
              <p className='d-flex'><i class="fa-solid contact-icon fa-location-dot"></i><span>428 ,building yamuna, Solitaire Valley Society, Jalalpur Ghosi, Allahabad, Allahabad, Uttar Pradesh, India, 211012</span></p>
              <p className='mb-4'><i class="fa-solid contact-icon fa-phone"></i>7275786295</p>
              <p className='mb-5'><i class="fa-solid contact-icon fa-envelope"></i>Resqshieldinfo@gmail.com</p>


              <Link to='https://www.facebook.com/share/1EcwPYRTvP/?mibextid=wwXIfr' target='_blank'>
              <i className="fa-brands contact-icon fa-facebook"></i></Link>
              <Link to='https://www.instagram.com/resqshield.app?igsh=MThkNTNqcDNuZ2RwbA==' target='_blank'>
              <i className="fa-brands contact-icon fa-instagram"></i></Link>
              <Link to='https://www.linkedin.com/company/resq-infratech-pvt-ltd/' target='_blank'>
              <i className="fa-brands contact-icon fa-linkedin-in"></i></Link>


            </ScrollAnimation>

          </div>
          {/* row ends */}


        </div>
      </section>

      {/* Map section */}
      <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3603.9978735035493!2d81.74745777538857!3d25.40488177757788!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjXCsDI0JzE3LjYiTiA4McKwNDUnMDAuMSJF!5e0!3m2!1sen!2sin!4v1732610946084!5m2!1sen!2sin"
      className='w-100' height="450" style={{border:'0'}} title='map location' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      {/* Map section ends */}

    </>
  )
}

export default Contact
